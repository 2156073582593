import { render, staticRenderFns } from "./TravelPersonalDetailsCa.vue?vue&type=template&id=35bcbb22&scoped=true"
import script from "./TravelPersonalDetailsCa.vue?vue&type=script&lang=js"
export * from "./TravelPersonalDetailsCa.vue?vue&type=script&lang=js"
import style0 from "./TravelPersonalDetailsCa.vue?vue&type=style&index=0&id=35bcbb22&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35bcbb22",
  null
  
)

export default component.exports