<template>
  <v-container
    class="light-blue lighten-5 pb-16 px-1"
    fluid
  >
    <v-row class="d-flex justify-center pb-16">
      <v-col
        cols="12"
        sm="11"
        md="9"
      >
        <v-card class="white px-auto">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane-check"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-airplane-check"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                complete-icon="mdi-airplane"
                complete
                step="3"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                complete-icon="mdi-airplane"
                step="4"
              >
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>

        <!-- LOGIN -->
        <div v-if="!userpresent">
          <v-row class="justify-center">
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-row class="d-flex justify-center">
                    <v-col
                      class="d-flex justify-center text-h3 text-center primary--text mb-6 mt-6"
                    >
                      Log In to your Account
                    </v-col>
                  </v-row>
                  <v-row>
                    {{ errorMessageAccount }}
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-form ref="form">
                        <validation-observer
                          ref="observer"
                          v-slot="{ invalid }"
                        >
                          <v-row class="d-flex justify-center">
                            <v-col class="pb-0">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required|email"
                              >
                                <v-text-field
                                  v-model="mailingEmail"
                                  label="Email"
                                  outlined
                                  append-icon="mdi-email-outline"
                                  :error-messages="errors"
                                  @click="clickedFieldGlobal('purchasePlan userEmail', 92, 'Canadian Travel Survey v1')"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col class="pb-0">
                              <validation-provider
                                v-slot="{ errors }"
                                vid="password"
                                name="Password"
                                rules="required"
                              >
                                <v-text-field
                                  ref="password"
                                  v-model="password"
                                  name="password"
                                  label="Password"
                                  outlined
                                  :type="show ? 'text' : 'password'"
                                  :append-icon="
                                    show ? 'mdi-eye-outline' : 'mdi-eye-off'
                                  "
                                  :error-messages="errors"
                                  @click:append="show = !show; clickedFieldGlobal('purchasePlan userPassword', 93, 'Canadian Travel Survey v1')"
                                >
                                </v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                          <v-row class="d-flex justify-center">
                            <v-col
                              cols="6"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                class="primary whtie--text mr-0"
                                block
                                large
                                :disabled="invalid"
                                @keyup.enter="submit"
                                @click="loginSubmit(); clickedFieldGlobal('purchasePlan logIn', 94, 'Canadian Travel Survey v1')"
                              >
                                <span class="login-btn">Log In</span>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </validation-observer>
                      </v-form>
                      <v-row class="d-flex justify-center">
                        <v-col>
                          <div class="signup-text text-center">
                            <span
                              style="color: #757575;"
                            >Don't have an account?</span>
                            <!-- <button
                              text
                              class="primary--text font-weight-bold"
                              @click="createAccount(); clickedFieldGlobal('purchasePlan createAccount', 95, 'Canadian Travel Survey v1')"
                            >
                              Create One For Me using: {{ mailingEmail }}
                            </button> -->
                            <create-account-button
                              :button-text="'Create One For Me using: ' + mailingEmail"
                              :button-classes="['primary--text','font-weight-bold','theme--light','v-btn--text','v-btn']"
                              :large="false"
                              :block="false"
                              :email="mailingEmail"
                              :password="password"
                              :post-signup-callback="postSignup"
                              :is-form-invalid="isEmailValid"
                              :text="true"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-card elevation="12">
          <v-row
            v-if="loading"
            class="mt-12"
          >
            <v-col class="d-flex justify-center">
              <ellipse-progress
                :loading="loading"
                :size="180"
                img-src="src/assets/images/Travel-Icon.png"
                thickness="5"
                empty-thickness="3"
                line-mode="out 5"
                legend="false"
                animation="rs 700 1000"
                font-size="1.5rem"
                color="#00A1B7"
                empty-color-fill="transparent"
                filename="Travel-Icon.png"
              >
              </ellipse-progress>
            </v-col>
          </v-row>
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <div
                    class="primary--text text-center mt-2 text-h5 font-weight-bold"
                  >
                    Purchase and download your policy
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    class="blanketsecondary--text mt-2 text-h3 font-weight-medium"
                  >
                    Personal Information
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                  >
                    <v-text-field
                      v-model="mailingFirstName"
                      label="First Name"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingFirstName',
                          96,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                  >
                    <v-text-field
                      v-model="mailingLastName"
                      label="Last Name"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingLastName',
                          97,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="mailingEmail"
                      label="Email"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingEmail',
                          98,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone"
                    rules="required"
                  >
                    <v-text-field
                      v-model="phone"
                      v-mask="'##########'"
                      label="Phone"
                      :rules="phoneNumberRules"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan phone',
                          99,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingAddress"
                      label="Address"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingAddress',
                          100,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="mailingSuite"
                    label="Apartment, suite, etc."
                    @click="
                      clickedFieldGlobal(
                        'purchasePlan mailingSuite',
                        101,
                        'Canadian Travel Survey v1'
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mailingCity"
                      label="City"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingCity',
                          102,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Province"
                    rules="required"
                  >
                    <v-autocomplete
                      v-model="mailingProvince"
                      :items="provinces"
                      item-text="name"
                      item-value="code"
                      label="Province"
                      :error-messages="errors"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingProvince',
                          103,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Postal Code"
                  >
                    <v-text-field
                      v-model="mailingZip"
                      v-mask="'A#A-#A#'"
                      label="Postal Code"
                      :error-messages="errors"
                      :rules="postalCodeRules"
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan mailingZip',
                          104,
                          'Canadian Travel Survey v1'
                        )
                      "
                      @input="mailingZip = mailingZip.toUpperCase()"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="checkbox"
                    label="Billing Info Same as Mailing Info"
                    @click="clickedFieldGlobal(
                      'purchasePlan isBillingAddrSame',
                      105,
                      'Canadian Travel Survey v1'
                    )"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <!----------------------Billing Address--------------------->
              <div v-if="!checkbox">
                <v-row>
                  <v-col>
                    <h1 class="primary--text mt-2">
                      Billing Information
                    </h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="First Name"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="billingFirstName"
                        label="First Name"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingFirstName',
                            106,
                            'Canadian Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last Name"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="billingLastName"
                        label="Last Name"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingLastName',
                            107,
                            'Canadian Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="billingEmail"
                        label="Email"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingEmail',
                            108,
                            'Canadian Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingAddress"
                        label="Address"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingAddress',
                            109,
                            'Canadian Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="billingSuite"
                      label="Apartment, suite, etc."
                      @click="
                        clickedFieldGlobal(
                          'purchasePlan billingSuite',
                          110,
                          'Canadian Travel Survey v1'
                        )
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingCity"
                        label="City"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingCity',
                            111,
                            'Canadian Travel Survey v1'
                          )
                        "
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Province"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="billingState"
                        :items="provinces"
                        item-text="name"
                        item-value="code"
                        label="Province"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingState',
                            112,
                            'Canadian Travel Survey v1'
                          )
                        "
                      ></v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Postal Code"
                      rules="required"
                    >
                      <v-text-field
                        v-model="billingZip"
                        v-mask="'A#A-#A#'"
                        label="Postal Code"
                        :rules="postalCodeRules"
                        :error-messages="errors"
                        @click="
                          clickedFieldGlobal(
                            'purchasePlan billingZip',
                            113,
                            'Canadian Travel Survey v1'
                          )
                        "
                        @input="billingZip = billingZip.toUpperCase()"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="travelDetails.additionalTravelers.length > 0">
                <v-col>
                  <v-card flat>
                    <v-card-title
                      class="blanketsecondary--text mt-2 text-h3 font-weight-medium"
                    >
                      Additional Traveller Information
                    </v-card-title>
                    <v-card-title class="mt-4 text-subtitle-1 mb-4">
                      Please verify the informaiton for the additional
                      travellers
                    </v-card-title>
                    <v-row
                      v-for="(item, index) in travelDetails.additionalTravelers"
                      :key="index"
                    >
                      <h3>Additional Traveller {{ index + 1 }}</h3>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Traveller First Name"
                              :rules="{
                                required: true,
                                regex: /^[a-zA-Z \-]+$/,
                              }"
                            >
                              <v-text-field
                                v-model="item.name"
                                label="Traveller First Name"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravFName',
                                  114,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Traveller Last Name"
                              :rules="{
                                required: true,
                                regex: /^[a-zA-Z \-]+$/,
                              }"
                            >
                              <v-text-field
                                v-model="item.lastname"
                                label="Traveller Last Name"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravLName',
                                  115,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Address"
                              rules="required"
                            >
                              <v-text-field
                                v-model="item.address"
                                label="Address"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravAddress',
                                  116,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="City"
                              rules="required"
                            >
                              <v-text-field
                                v-model="item.city"
                                label="City"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravCity',
                                  117,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Province"
                              rules="required|alpha"
                            >
                              <v-autocomplete
                                v-model="item.province"
                                label="Province"
                                :items="provinces"
                                item-text="name"
                                item-value="code"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravProvince',
                                  118,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Postal Code"
                              rules="required"
                            >
                              <v-text-field
                                v-model="item.zipcode"
                                v-mask="'A#A-#A#'"
                                label="Postal Code"
                                :rules="postalCodeRules"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravPostalCode',
                                  119,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Phone Number"
                              rules="required"
                            >
                              <v-text-field
                                v-model="item.phone"
                                v-mask="'##########'"
                                label="Phone"
                                :rules="phoneNumberRules"
                                :error-messages="errors"
                                @click="clickedFieldGlobal(
                                  'purchasePlan additionalTravPhone',
                                  120,
                                  'Canadian Travel Survey v1'
                                )"
                              ></v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card flat>
                    <v-card-title
                      class="blanketsecondary--text mt-2 text-h3 font-weight-medium"
                    >
                      Terms & Agreements
                    </v-card-title>
                    <v-card-title class="mt-4 text-subtitle-1">
                      If you are not covered by a provincial or territory
                      government health care plan at the time of a claim, your
                      Emergency Medical Insurance will be limited to a maximum
                      of $50,000.
                    </v-card-title>
                    <v-card-text class="mt-4 text-subtitle-2">
                      At the time of application, you are eligible for coverage
                      if:
                      <v-list
                        subheader
                        two-line
                        flat
                      >
                        <v-list-item>
                          You are a Canadian resident.
                        </v-list-item>
                        <v-list-item>
                          You are not travelling against a physician or any
                          other registered medical practitioner’s advice.
                        </v-list-item>
                        <v-list-item>
                          You have not been diagnosed with a terminal condition.
                        </v-list-item>
                        <v-list-item>
                          You are not receiving palliative care or palliative
                          care has not been recommended.
                        </v-list-item>
                        <v-list-item>
                          <v-checkbox
                            v-model="termsagreed"
                            label="I have read and agree with the Terms & Conditions"
                            @click="
                              clickedFieldGlobal(
                                'purchasePlan termsagreed',
                                121,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-checkbox>
                        </v-list-item>
                        <v-list-item>
                          <v-checkbox
                            v-model="termsprovince"
                            label="I am currently in my home province"
                            @click="
                              clickedFieldGlobal(
                                'purchasePlan termsprovince',
                                122,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-checkbox>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <v-row class="justify-center">
                    <div class="grey--text text-h5 font-weight-bold ma-2 pa-2">
                      Your Purchase Plan Details
                    </div>
                  </v-row>
                  <v-row class="justify-center white">
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="justify-center"
                    >
                      <v-card elevation="0">
                        <v-card-title class="blanketsecondary--text">
                          SELECTED PLANS(S)
                        </v-card-title>
                        <v-card-text>
                          <p>
                            Plan prices including taxes and minimum charges if
                            applicable.
                          </p>
                        </v-card-text>
                        <v-card-text
                          v-for="(item, index) of finalpricing"
                          :key="index"
                          class="primary--text"
                        >
                          {{ item.genericName }} <br />
                          {{ formatAmount(item.chargedPrice.total) }}
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="d-flex justify-center blanketsecondary--text"
                    >
                      <v-divider vertical></v-divider>
                      <v-card elevation="0">
                        <v-card-title class="blanketsecondary--text">
                          TRIP DETAILS
                        </v-card-title>
                        <v-card-text>
                          Start Date: {{ travelDetails.startDate }}
                        </v-card-text>
                        <v-card-text>
                          End Date: {{ travelDetails.endDate }}
                        </v-card-text>
                        <v-card-text>
                          Origin: {{ travelDetails.origin }}
                        </v-card-text>
                        <v-card-text>
                          Destination: {{ travelDetails.destination }}
                          {{ travelDetails.primaryDestination }}
                        </v-card-text>
                        <v-card-title class="blanketsecondary--text">
                          TRAVELER INFORMATION
                        </v-card-title>
                        <v-card-text>
                          Traveler 1, {{ travelDetails.primaryTravellerName }},
                          Age: {{ travelDetails.primaryTravellerAge }}
                        </v-card-text>
                        <v-card-text v-if="travelDetails.additionalTravelers">
                          <span
                            v-for="(item,
                                    i) in travelDetails.additionalTravelers"
                            :key="i"
                          >
                            Traveler {{ i + 2 }}, {{ item.name }}, Age:
                            {{
                              calc_age(
                                item.birthyear,
                                item.birthmonth,
                                item.birthday
                              )
                            }}
                          </span>
                        </v-card-text>
                      </v-card>
                      <v-divider vertical></v-divider>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="d-flex justify-center blanketsecondary--text"
                    >
                      <v-card
                        elevation="0"
                        color="#DEECF1"
                      >
                        <v-img src="/img/tripdetailcanada.png"></v-img>
                        <v-card-title class="blanketsecondary--text">
                          TOTAL PREMIUM
                        </v-card-title>
                        <v-card-title class="blanketsecondary--text">
                          {{ finalTotal }}
                        </v-card-title>
                        <v-card-subtitle class="mt-2">
                          Including Taxes and Mimimum Charges
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="blanketsecondary--text mt-2 display-2 font-weight-medium"
                >
                  Credit Card Information
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Cardholder Name"
                  >
                    <v-text-field
                      v-model="cardholder"
                      label="Cardholder Name"
                      :error-messages="errors"
                      outlined
                      @click="clickedFieldGlobal('cardholder', 43, 'Canadian Travel Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Card Number (no spaces)"
                  >
                    <v-text-field
                      v-model="cardnumber"
                      v-mask="'####-####-####-####'"
                      label="Card Number (no spaces)"
                      :error-messages="errors"
                      outlined
                      @click="clickedFieldGlobal('cardnumber', 44, 'Canadian Travel Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Exp Month (MM)"
                  >
                    <v-text-field
                      v-model="cardexpmonth"
                      v-mask="'##'"
                      label="Exp Month (MM)"
                      :error-messages="errors"
                      outlined
                      @click="clickedFieldGlobal('cardexpmonth', 45, 'Canadian Travel Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Card Exp Year (YYYY)"
                  >
                    <v-text-field
                      v-model="cardexpyear"
                      v-mask="'####'"
                      label="Card Exp Year (YYYY)"
                      :error-messages="errors"
                      outlined
                      @click="clickedFieldGlobal('cardexpyear', 46, 'Canadian Travel Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Card Security Code CVV"
                  >
                    <v-text-field
                      v-model="cardsecuritycode"
                      v-mask="'###'"
                      label="Card Security Code CVV"
                      :error-messages="errors"
                      outlined
                      @click="clickedFieldGlobal('cardsecuritycode', 47, 'Canadian Travel Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row> -->
              <v-credit-card
                v-if="userpresent"
                :year-digits="4"
                :class="mobileDevice ? 'pa-0' : ''"
                @change="handleCCData"
                @cardChanged="cardChanged"
              ></v-credit-card>
              <v-row
                v-if="loading"
                class="mt-12"
              >
                <v-col class="d-flex justify-center">
                  <ellipse-progress
                    :loading="loading"
                    :size="180"
                    img-src="src/assets/images/Travel-Icon.png"
                    thickness="5"
                    empty-thickness="3"
                    line-mode="out 5"
                    legend="false"
                    animation="rs 700 1000"
                    font-size="1.5rem"
                    color="#00A1B7"
                    empty-color-fill="transparent"
                    filename="Travel-Icon.png"
                  >
                  </ellipse-progress>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div
                    v-for="(errorMessage, idx) of canadianTravelErrors"
                    :key="idx"
                  >
                    {{ errorMessage.text }}
                  </div>
                  <div class="d-flex justify-center">
                    <v-btn
                      x-large
                      :disabled="invalid || !termsagreed"
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="submit(); clickedFieldGlobal(
                        'purchasePlan purchasePolicy',
                        123,
                        'Canadian Travel Survey v1'
                      )"
                    >
                      Purchase Policy
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-dialog
      v-model="ccError"
      max-width="700"
      persistent
    >
      <v-card>
        <v-card-title class="justify-center font-weight-bold headline red--text">
          Error
        </v-card-title>
        <v-card-title class="justify-center text-center body primmary--text mt-4">
          <v-row><v-col>Please ensure credit card information including first and last name is valid. Valid card types are: Visa, Mastercard and American Express</v-col></v-row>
        </v-card-title>
        <v-row>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-2 mt-4 font-weight-bold"
                @click="(ccError = false)"
              >
                Close
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->
    <error-dialog
      :error-dialog="ccError"
      :error-message="'Please ensure credit card information including first and last name is valid. Valid card types are: Visa, Mastercard and American Express'"
      :custom-error-method="true"
      :custom-error-close-button="'Close'"
      @customErrorCloseEvent="ccError = false"
    ></error-dialog>
    <error-dialog
      :error-dialog="purchaseError"
      :error-message="error"
      :custom-error-method="true"
      :custom-error-close-button="'Close'"
      @customErrorCloseEvent="clearError(), (purchaseError = false)"
    ></error-dialog>
    <otp-verification
      path="src/assets/images/Travel-Icon.png"
      filename="Travel-Icon.png"
    />
  </v-container>
</template>

<script>
  import moment from 'moment'
  import VCreditCard from 'v-credit-card'
  import 'v-credit-card/dist/VCreditCard.css'
  import CreateAccountButton from '../../../components/base/CreateAccountBtn'
  let { AgeFromDateString, AgeFromDate } = require('age-calculator')
  var numeral = require('numeral')

  export default {
    components: {
      // BorderFree,
      VCreditCard,
      CreateAccountButton
    },
    data () {
      return {
        purchaseError: false,
        provinces: [
          { name: 'Alberta', code: 'AB' },
          { name: 'British Columbia', code: 'BC' },
          { name: 'Manitoba', code: 'MB' },
          { name: 'New Brunswick', code: 'NB' },
          { name: 'Newfoundland and Labrador', code: 'NL' },
          { name: 'Nova Scotia', code: 'NS' },
          { name: 'Ontario', code: 'ON' },
          { name: 'Prince Edward Island', code: 'PE' },
          { name: 'Saskatchewan', code: 'SK' },
        ],
        ccError: false,
        checkbox: true,
        password: '',
        show: '',
        phone: '',
        travelProducts: '',
        travelDetails: '',
        termsprovince: '',
        termsagreed: false,
        cardexp: '',
        cardholder: '',
        cardnumber: '', // 4030000010001234
        creditCardType: '',
        cardsecuritycode: '', // 123
        mailingFirstName: '',
        mailingLastName: '',
        mailingEmail: '',
        mailingAddress: '',
        mailingSuite: '',
        mailingCity: '',
        mailingProvince: '',
        mailingZip: '',
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingAddress: '',
        billingSuite: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        finalAmount: '',
        methodPaymentCd: '',
        postalCodeRules: [
          (v) => !!v || 'Postal code is required',
          (v) =>
            /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(v) ||
            'Postal code has wrong format',
          (v) =>
            (v &&
              v.length > 6 &&
              /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/.test(
                v
              )) ||
            'Invalid Canadian postal code',
        ],
        phoneNumberRules: [
          (v) => !!v || 'Phone number is required',
          (v) => (v && v > 0) || 'Invalid phone number',
        ],
      }
    },
    computed: {
      error () {
        return this.$store.getters.getErrors
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      errorMessageAccount () {
        let message = ''
        message = this.$store.getters.getErrors
        return message
      },
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      formattedCCNumber () {
        if (this.cardnumber) {
          return this.cardnumber.replace(/ /g, '')
        } else {
          return null
        }
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      payments () {
        // valid cc types: VISA, MASTERCARD, AMERICANEXPRESS
        return [
          {
            transactionType: 'PURCHASE',
            paymentType: 'CREDITCARD',
            paymentMethod: 'SINGLE',
            currencyCode: 'CAD',
            amount: this.finalAmount,
            creditCardNumber: this.cardnumber,
            creditCardType: this.methodPaymentCd,
            cardHolder: this.cardholder,
            cardExpirationDate: this.cardexp,
            cardCVV: this.cardsecuritycode,
          },
        ]
      },
      insuredPersons () {
        return this.$store.getters.getInsuredPersons
      },
      finalTotal () {
        console.log(
          'total calc ',
          this.$store.getters.getTravelInsuranceCanadaQuoteFinal
        )
        let finalTotal = 0
        this.finalpricing.forEach((item) => {
          finalTotal += item.chargedPrice.total
        })
        return numeral(finalTotal).format('$0.00')
      },
      finalpricing () {
        console.log(
          'total calc ',
          this.$store.getters.getTravelInsuranceCanadaQuoteFinal
        )
        let finalselections = [
          {
            selectedPlans: '',
            totalPrice: '',
          },
        ]
        if (this.$store.getters.getTravelInsuranceCanadaQuoteFinal) {
          finalselections = this.$store.getters.getTravelInsuranceCanadaQuoteFinal
            .availablePlanPrices
        }
        console.log('final selections detail ', finalselections)
        return finalselections
      },
      canadianTravelErrors () {
        let error = ''
        // this.$store.getters.getTravelCoverageDetails
        error = this.$store.getters.getCanadianTravelError
        console.log('errors are ', error)
        return error
      },
      selectedCost () {
        return numeral(0).format('$0.00')
      },
      mailingInfo () {
        return {
          firstName: this.mailingFirstName,
          lastName: this.mailingLastName,
          email: this.mailingEmail,
          suite: this.mailingSuite,
          city: this.mailingCity,
          province: this.mailingProvince,
          zip: this.mailingZip.replace(/[-\s]/g, ''),
        }
      },
      billingInfo () {
        return {
          firstName: this.billingFirstName,
          lastName: this.billingLastName,
          email: this.billingEmail,
          suite: this.billingSuite,
          city: this.billingCity,
          state: this.billingState,
          zip: this.billingZip.replace(/[-\s]/g, ''),
        }
      }
    },
    created () {
      this.$store.dispatch('resetTravelCanadaError')
      this.travelOptions = this.$store.getters.getTravelCoverageDetails
      console.log('traveloptions are ', this.travelOptions)
      this.travelDetails = this.$store.getters.getTravelDetails
      console.log('our details are ', this.travelDetails)
    // final prices
    },
    beforeDestroy () {
      this.$store.dispatch('resetTravelCanadaError')
    },
    methods: {
      clearError () {
        this.$store.dispatch('clearError')
      },
      createAccount () {
        // put account creation function here
        console.log('creating account with ', this.mailingEmail)
        this.$store.dispatch('createUserAndProfileForUser', {
          email: this.mailingEmail,
        })
      },
      loginSubmit () {
        // log user in
        console.log('login with ', this.mailingEmail, this.password)
        this.$store.dispatch('signUserIn', {
          email: this.mailingEmail,
          password: this.password,
        })
      },
      cardChanged (cardName) {
        console.log(cardName)
        switch (cardName) {
          case 'Visa':
            this.methodPaymentCd = 'VISA'
            break
          case 'Mastercard':
            this.methodPaymentCd = 'MASTERCARD'
            break
          case 'Amex':
            this.methodPaymentCd = 'AMERICANEXPRESS'
            break
          default:
            this.methodPaymentCd = ''
        }
      },
      handleCCData (values) {
        console.log(values)
        this.cardnumber = values.cardNumber
        console.log('formatted number', this.formattedCCNumber)
        if (values.expiration.length === 7) {
          this.cardexp = values.expiration.split('/').reverse().join('-')
          console.log(this.cardexp)
          if (moment(this.cardexp).isBefore(moment().format('YYYY-MM'))) {
            this.ccError = true
          } else {
            this.ccError = false
          }
        }
        // mrmr card component
        this.cardholder = values.name
        this.cardsecuritycode = values.security
        console.log('ccname ', this.ccName)
        console.log('exp ', this.cardexp)
      },
      calc_age (year, month, day) {
        let monthnum = moment().month(month).format('MM')

        // get current year
        let ageFromString = new AgeFromDateString(
          year + '-' + monthnum + '-' + day
        ).age
        console.log('value from ageFromString', ageFromString)
        // get month number
        let as = year + '/' + monthnum + '/' + day
        console.log(as)
        let birthDate = new Date(as.toString())
        console.log(' birthDate' + birthDate)

        // get difference from current date;
        let difference = Date.now() - birthDate.getTime()

        let ageDate = new Date(difference)
        let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)
        return calculatedAge
      },
      formatAmount (val) {
        return numeral(val).format('$0.00')
      },
      async submit () {
        let complete = await this.$refs.observer.validate()
        console.log(complete)

        if (!complete) {
          console.log('error submitting form')
          return
        }

        if (!this.isValidPaymentMethod()) {
          this.ccError = true
          return
        }

        this.$store.dispatch('resetTravelCanadaError')
        let selectedplans = this.getSelectedPlans()

        console.log('MULTI PLAAAANNN  ', selectedplans)
        console.log('travel options ', this.travelOptions)

        let additionalTravelers = this.getAdditionalTravelers()
        let insuredPersons = this.getInsuredPersons(additionalTravelers)

        console.log('INSURED PERSONS', insuredPersons)

        let finalTotal = this.calculateFinalTotal()

        console.log('FINAL TOTAL *********** ', finalTotal)

        let purchaseRecord = this.getPurchaseRecord(finalTotal, insuredPersons)

        console.log('travel details are!!!!  ', this.travelDetails)
        console.log('purchase from vue are!!!!  ', purchaseRecord)
        console.log('Error before', this.error)

        this.finalizePurchase(purchaseRecord)
      },

      isValidPaymentMethod () {
        let validCards = ['VISA', 'MASTERCARD', 'AMERICANEXPRESS']
        return validCards.includes(this.methodPaymentCd)
      },

      getSelectedPlans () {
        return this.$store.getters.getTravelInsuranceCanadaQuoteFinal.availablePlanPrices.map(
          (entry) => ({ planCode: entry.planCode })
        )
      },

      getAdditionalTravelers () {
        return this.travelDetails.additionalTravelers.map((entry, index) => ({
          insuredType: 'CANADIAN',
          inHomeProvince: true,
          homeProvince: entry.province,
          firstName: entry.name,
          lastName: entry.lastname,
          birthDate: moment(
            entry.birthyear + '-' + entry.birthmonth + '-' + entry.birthday
          ).format('YYYY-MM-DD'),
          phoneNumbers: [
            {
              number: entry.phone,
            },
          ],
          addresses: [
            {
              type: 'MAIL',
              addressLine1: entry.address,
              city: entry.city,
              country: 'CA',
              province: entry.province,
              postalCode: entry.zipcode,
            },
          ],
          selectedPlans: this.insuredPersons[index + 1].selectedPlans,
        }))
      },

      getInsuredPersons (additionalTravelers) {
        let insuredPersons = [
          {
            insuredType: 'CANADIAN',
            inHomeProvince: true,
            homeProvince: 'BC',
            firstName: this.mailingFirstName,
            lastName: this.mailingLastName,
            birthDate: this.travelDetails.primaryTravellerDob,
            phoneNumbers: [
              {
                number: this.phone,
              },
            ],
            addresses: [
              {
                type: 'MAIL',
                addressLine1: this.mailingAddress,
                city: this.mailingCity,
                country: 'CA',
                province: this.mailingProvince,
                postalCode: this.mailingZip.replace(/[-\s]/g, ''),
              },
            ],
            selectedPlans: this.insuredPersons[0].selectedPlans,
          },
        ]

        return insuredPersons.concat(additionalTravelers)
      },

      calculateFinalTotal () {
        return this.finalpricing.reduce(
          (total, item) => total + item.chargedPrice.total,
          0
        )
      },

      getPurchaseRecord (finalTotal, insuredPersons) {
        return {
          policyInfo: {},
          payments: [
            {
              transactionType: 'PURCHASE',
              paymentType: 'CREDITCARD',
              paymentMethod: 'SINGLE',
              currencyCode: 'CAD',
              amount: finalTotal,
              creditCardNumber: this.cardnumber.replace(/\s/g, ''),
              creditCardType: this.methodPaymentCd,
              cardHolder: this.cardholder,
              cardExpirationDate: this.cardexp,
              cardCVV: this.cardsecuritycode,
            },
          ],
          trip: {
            startDate: this.travelDetails.startDate,
            endDate: this.travelDetails.endDate,
          },
          primaryDestination: this.travelDetails.primaryDestination,
          insuredPersons: insuredPersons,
        }
      },

      finalizePurchase (purchaseRecord) {
        this.$store
          .dispatch('purchaseTravelInsuranceCanada', purchaseRecord)
          .then(() => {
            if (this.error) {
              console.log('Error after', this.error)
              this.purchaseError = true
            } else {
              this.$router.push({ path: '/Travelpolicyca' })
            }
          })
      },
      postSignup () {
        this.clickedFieldGlobal('purchasePlan createAccount', 95, 'Canadian Travel Survey v1')
      }
    },
  }
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls.v-input .v-label {
  font-weight: 500 !important;
  color: black !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 430px) {
  .vue-credit-card {
    margin-left: -2.1rem;
    width: 120%;
  }
}
</style>
